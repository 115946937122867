import Image from 'next/image'
import DialogBase from '../DialogBase/DialogBase'
import PaymentDetails from '../PaymentDetails/PaymentDetails'

import dialogStyles from '../DialogBase/dialogBase.module.scss'

type Props = {
  isModalOpen: boolean
  handleClose: () => Promise<void>
}

export default function PaymentModal({ isModalOpen, handleClose }: Props) {
  return (
    <DialogBase openDialog={isModalOpen} handleClose={handleClose} size={'xl'}>
      <div className={dialogStyles.modal_box}>
        <div className={dialogStyles.modal_header}>
          <div className={`${dialogStyles.modal_title}`}>
            TopicRanker Subscription
          </div>
          <Image
            src="/assets/blackCross.svg"
            alt="logo"
            width={15}
            height={15}
            className={dialogStyles.modal_close}
            onClick={handleClose}
          />
        </div>
        <PaymentDetails isSignUp={false} />
      </div>
    </DialogBase>
  )
}
