import { SubscriptionType as SubType } from '@prisma/client'
import { SUBSCRIPTION_TYPES } from './consts'

export const getCurrentSubName = (subTier: SubType | undefined) => {
  if (subTier === undefined) {
    return ''
  }
  switch (subTier) {
    case SUBSCRIPTION_TYPES.FREE.id:
      return 'FREE'
    case SUBSCRIPTION_TYPES.PREMIUM.id:
      return 'Premium'
    case SUBSCRIPTION_TYPES.BASIC.id:
      return 'Basic'
    case SUBSCRIPTION_TYPES.STARTER.id:
      return 'Starter'
    default:
      return 'Unkown'
  }
}
