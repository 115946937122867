import Image from 'next/image'
import DialogBase from '../DialogBase/DialogBase'
import { SubscriptionType as SubType } from '@prisma/client'
import dialogStyles from './subCancelModal.module.scss'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'
import { useRouter } from 'next/router'

type Props = {
  isModalOpen: boolean
  nextInvoiceDate: string
  cancelText: {
    type: SubType
    annual: boolean
  }
  handleClose: () => void
}

export default function SubCancelModal({
  isModalOpen,
  nextInvoiceDate,
  cancelText,
  handleClose,
}: Props) {
  const router = useRouter()
  return (
    <DialogBase openDialog={isModalOpen} size="sm" handleClose={handleClose}>
      <div className={dialogStyles.modal_box}>
        <div className={dialogStyles.modal_header}>
          <div
            className={`${dialogStyles.modal_title} ${dialogStyles.gradientPurpleText}`}
          >
            Subscription change
          </div>
          <Image
            src="/assets/blackCross.svg"
            alt="logo"
            width={15}
            height={15}
            className={dialogStyles.modal_close}
            onClick={() => {
              handleClose()
              router.push('/dashboard?cancel=true')
            }}
          />
        </div>
        <div className={dialogStyles.modal_container_column}>
          <div className={dialogStyles.modal_text}>
            Your{cancelText.annual ? ' annual ' : ' '}
            {cancelText.type === SUBSCRIPTION_TYPES.PREMIUM.id
              ? 'Premium '
              : 'Basic '}
            subscription has been cancelled and you will no longer be billed.
            You will continue to have access to{' '}
            <strong>
              {cancelText.type === SUBSCRIPTION_TYPES.PREMIUM.id
                ? 'Premium '
                : 'Basic '}
            </strong>{' '}
            features until <strong>{nextInvoiceDate}</strong>.
          </div>
          <button
            onClick={() => {
              handleClose()
              router.push('/dashboard?cancel=true')
            }}
            className={`${dialogStyles.modal_button} ${dialogStyles.gradientBlack}`}
          >
            Done
          </button>
        </div>
      </div>
    </DialogBase>
  )
}
