import Image from 'next/image'
import { useRouter } from 'next/router'
import DialogBase from '../DialogBase/DialogBase'

import dialogStyles from '../DialogBase/dialogBase.module.scss'

type Props = {
  isModalOpen: boolean
  isPaymentSuccessful: boolean
  handleClose?: () => void
  handleClick: () => void
  gradeType?: string
  nextPlan: string
}

export default function PaymentResultModal({
  isModalOpen,
  isPaymentSuccessful,
  handleClose,
  handleClick,
  gradeType,
  nextPlan,
}: Props) {
  const router = useRouter()
  return (
    <DialogBase openDialog={isModalOpen} handleClose={handleClose}>
      <div className={dialogStyles.modal_box}>
        {isPaymentSuccessful ? (
          <div className={dialogStyles.modal_container}>
            <div className={dialogStyles.modal_left}>
              <div
                className={`${dialogStyles.modal_circle} ${dialogStyles.gradientGreenBorder}`}
              >
                <div className={dialogStyles.modal_circle_tick_img}>
                  <Image
                    src="/assets/greenTick.svg"
                    alt="logo"
                    width={105}
                    height={55}
                  />
                </div>
              </div>
              {router.query.subscribed ? (
                <span
                  className={`${dialogStyles.modal_state_text} ${dialogStyles.gradientGreenText}`}
                >
                  Your account has been upgraded!
                </span>
              ) : (
                <span
                  className={`${dialogStyles.modal_state_text} ${dialogStyles.gradientGreenText}`}
                >
                  Your account has been {gradeType || 'upgraded'} to {nextPlan}{' '}
                  plan!
                </span>
              )}
            </div>
            <div className={dialogStyles.modal_right}>
              <span className={dialogStyles.modal_text}>
                {router.query.subscribed
                  ? 'Ready to find those easy to rank keywords?'
                  : "Now let's find those easy to rank keywords!"}
              </span>
              <button
                className={`${dialogStyles.modal_button} ${dialogStyles.gradientYellow}`}
                onClick={handleClick}
              >
                Let&apos;s do it
              </button>
            </div>
          </div>
        ) : (
          <div className={dialogStyles.modal_container}>
            <div className={dialogStyles.modal_left}>
              <div
                className={`${dialogStyles.modal_circle} ${dialogStyles.gradientRedBorder}`}
              >
                <div className={dialogStyles.modal_circle_cross_img}>
                  <Image
                    src="/assets/redCross.svg"
                    alt="logo"
                    width={60}
                    height={60}
                  />
                </div>
              </div>
              <span
                className={`${dialogStyles.modal_state_text} ${dialogStyles.gradientRedText}`}
              >
                Your subscription change failed!
              </span>
            </div>
            <div className={dialogStyles.modal_right}>
              <span className={dialogStyles.modal_text}>
                It looks like something went wrong...
              </span>
              <button
                onClick={handleClose}
                className={`${dialogStyles.modal_button} ${dialogStyles.gradientBlack}`}
              >
                Try again
              </button>
            </div>
          </div>
        )}
      </div>
    </DialogBase>
  )
}
