import { useSession } from 'next-auth/react'
import Image from 'next/image'
import { Profile } from '../../interfaces/profile'
import {
  Prices,
  SemrushSubscriptionType,
  SubscriptionType,
} from '../../interfaces/subscription'
import LoadingSpinner from '../Loader/Loader'
import { SubscriptionType as SubType } from '@prisma/client'
import StyledTooltip from '@components/StyledTooltip/StyledTooltip'
import classNames from 'classnames'

import styles from './pricingCard.module.scss'
import { SemrushTypes } from '../Pricing'

type Props = {
  subDetails: SubscriptionType | SemrushSubscriptionType
  isActive?: boolean
  semrushLanding?: boolean
  isAnnual?: boolean
  profile?: Profile | null
  isSettingsPage?: boolean
  handleSemrushClick: (priceType: SemrushTypes) => void
  handleClick: (priceType: SubType) => Promise<void>
  prices: Prices
  isButtonDisabled: boolean
}

export default function PricingCard({
  subDetails,
  isAnnual = false,
  isSettingsPage = false,
  profile = null,
  isActive = false,
  semrushLanding = false,
  handleClick,
  handleSemrushClick,
  prices,
  isButtonDisabled,
}: Props) {
  const { status } = useSession()
  const authenticated = status === 'authenticated'

  let isBase: boolean
  if (semrushLanding) {
    isBase = subDetails.name.toLocaleLowerCase() === 'premium'
  } else {
    isBase = subDetails.name.toLocaleLowerCase() === 'basic'
  }

  return (
    <div
      className={classNames(styles.cardContainer, {
        [styles.blue_background]: isBase,
      })}
    >
      <div
        className={classNames(styles.card, {
          [styles.unbordered]:
            (isSettingsPage || (!isSettingsPage && authenticated)) && isActive,
        })}
      >
        <div
          className={classNames(styles.cardContent, {
            [styles.middleCard]: isBase,
            [styles.semrush]: semrushLanding,
          })}
        >
          <div className={styles.priceContainer}>
            <h3
              className={classNames(styles.pricingName, {
                [styles.semrush]: semrushLanding,
              })}
            >
              {subDetails.name}
            </h3>
            {prices.annual === null || prices.monthly === null ? (
              <div className={styles.loaderWrap}>
                <LoadingSpinner height="32px" />
              </div>
            ) : (
              <>
                <div className={styles.pricingValue}>
                  {'$' + String(isAnnual ? prices.annual : prices.monthly)}
                  <span className={styles.frequency}> / mo.</span>
                </div>
                <div className={styles.pricingAnnually}>
                  {isAnnual && 'Billed annually'}
                </div>
              </>
            )}
          </div>

          <div className={styles.buttonContainer}>
            <a>
              {isSettingsPage || (!isSettingsPage && authenticated) ? (
                <button
                  className={classNames({
                    [styles.btnDisabled]: isButtonDisabled,
                    [styles.selectBtn]: isActive,
                    [styles.signUpBtn]: !isActive,
                  })}
                  disabled={isActive || isButtonDisabled}
                  style={isActive ? { cursor: 'not-allowed' } : {}}
                  onClick={async () => {
                    if (
                      subDetails.id === 'SEMRUSH_BASIC' ||
                      subDetails.id === 'SEMRUSH_PREMIUM'
                    )
                      return
                    await handleClick(subDetails.id)
                  }}
                >
                  {isActive ? 'active' : 'switch'}
                </button>
              ) : (
                <button
                  className={classNames(styles.signUpBtn, {
                    [styles.yellowButton]: isBase,
                    [styles.semrush]: semrushLanding,
                  })}
                  onClick={() => {
                    if (
                      subDetails.id === 'SEMRUSH_BASIC' ||
                      subDetails.id === 'SEMRUSH_PREMIUM'
                    ) {
                      handleSemrushClick(subDetails.id)
                    } else {
                      handleClick(subDetails.id)
                    }
                  }}
                >
                  {semrushLanding ? (
                    <>
                      Sign up at{' '}
                      <img
                        src={'/assets/landing/semrush_logo.png'}
                        className={styles.semrushLogo}
                        alt="Semrush"
                      />
                    </>
                  ) : (
                    'sign up'
                  )}
                </button>
              )}
            </a>
          </div>

          <div>
            <ul className={styles.infoList}>
              {subDetails.perks.map(({ desc, tooltip }) => (
                <li key={desc}>
                  <div className={styles.info}>
                    <div className={styles.infoCheck}>
                      <Image
                        alt="check"
                        src="/assets/check_green.svg"
                        width={20}
                        height={20}
                      />
                    </div>
                    <p className={styles.infoText}>{desc}</p>
                    {tooltip && (
                      <StyledTooltip content={tooltip}>
                        <div className={styles.infoAlert}>
                          <Image
                            alt="alert"
                            src={`/assets/alert_sign_${
                              isBase ? `white` : `grey`
                            }.svg`}
                            width={28}
                            height={28}
                          />
                        </div>
                      </StyledTooltip>
                    )}
                  </div>
                </li>
              ))}
              {subDetails.nonPerks?.map(({ desc, tooltip }) => (
                <li key={desc}>
                  <div className={styles.info}>
                    <div className={styles.infoCheck}>
                      <Image
                        alt="check"
                        src="/assets/red_close.svg"
                        width={20}
                        height={20}
                      />
                    </div>
                    <p className={`${styles.infoText} ${styles.gray}`}>
                      {desc}
                    </p>
                    {tooltip && (
                      <StyledTooltip content={tooltip}>
                        <div className={styles.infoAlert}>
                          <Image
                            alt="alert"
                            src={`/assets/alert_sign_${
                              isBase ? `white` : `grey`
                            }.svg`}
                            width={28}
                            height={28}
                          />
                        </div>
                      </StyledTooltip>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {profile?.subscriptionData?.subscriptionId && (
        <div
          className={classNames({
            [styles.actualPlan]:
              profile?.subscriptionData.nextPlan === subDetails.id,
            [styles.textHide]:
              profile?.subscriptionData.nextPlan !== subDetails.id,
          })}
        >
          This is your plan now.
        </div>
      )}
    </div>
  )
}
