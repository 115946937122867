import DialogBase from '../DialogBase/DialogBase'
import { Profile } from '../../interfaces/profile'
import Image from 'next/image'
import { SubscriptionType as SubType } from '@prisma/client'
import dialogStyles from '../DialogBase/dialogBase.module.scss'
import CouponField from '../CouponField/CouponField'
import { getCurrentSubName } from '../../utils/subNameHelper'

type Props = {
  profile: Profile
  isModalOpen: boolean
  isAnnualPlan?: boolean
  priceType: SubType
  chargeFee: number
  handleClose: () => void
  upgradeSubscription: () => Promise<void>
  gradeType: (activePlan: SubType) => 'Upgrade' | 'Downgrade'
  computeChargeFee: () => Promise<void>
}

export default function SubChangeModal({
  profile,
  isModalOpen,
  isAnnualPlan = false,
  priceType,
  chargeFee,
  handleClose,
  upgradeSubscription,
  gradeType,
  computeChargeFee,
}: Props) {
  return !profile.subscriptionData?.subscriptionId ? null : (
    <DialogBase openDialog={isModalOpen} handleClose={handleClose}>
      <div className={dialogStyles.modal_box}>
        <div className={dialogStyles.modal_header}>
          <div
            className={`${dialogStyles.modal_title} ${dialogStyles.gradientPurpleText}`}
          >
            Subscription change
          </div>
          <Image
            src="/assets/x_circle_black.svg"
            alt="logo"
            width={40}
            height={40}
            className={dialogStyles.modal_close}
            onClick={handleClose}
          />
        </div>
        <div className={dialogStyles.modal_container_column}>
          <div className={dialogStyles.modal_text}>
            You are about to change your subscription<br></br>from
            {profile?.subscriptionData?.isAnnual ? ' annual ' : ' '}
            {`${getCurrentSubName(profile?.subscriptionData?.activePlan)} `}to
            {isAnnualPlan ? ' annual ' : ' '}
            {getCurrentSubName(priceType)}.
          </div>
          <div className={dialogStyles.modal_text}>
            Hence we will charge
            <strong> ${chargeFee} from your card.</strong>
          </div>
          {profile && (
            <CouponField
              subscriptionID={profile?.subscriptionData?.subscriptionId}
              handleCouponSuccess={computeChargeFee}
            />
          )}
          <button
            onClick={upgradeSubscription}
            className={`${dialogStyles.modal_button} ${dialogStyles.gradientGreen}`}
          >
            {gradeType(profile.subscriptionData.activePlan)}
          </button>
        </div>
      </div>
    </DialogBase>
  )
}
