import { Backdrop } from '@mui/material'
import LoadingSpinner from '../Loader/Loader'

import styles from './loadingOverlay.module.scss'

export default function LoadingOverlay({
  isOpen = true,
}: {
  isOpen?: boolean
}) {
  return (
    <div className={styles.root}>
      <Backdrop open={isOpen} timeout={700}>
        <div className={styles.container}>
          <LoadingSpinner />
          <p>Please Wait</p>
        </div>
      </Backdrop>
    </div>
  )
}
